import axios from 'axios';
import jwt_decode from "jwt-decode";
import * as R from 'ramda';


const getAuthenticationUrl = (state) => {
    var nonce = "";
    var possible = "0123456789";
    for(var i = 0; i < 12; i++) {
        nonce += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    const hostname = window && window.location && window.location.hostname;
    return "https://accounts.securibox.eu/id/connect/authorize?client_id=D58E0FEF-0844-4B46-94AC-BE691D415F03&redirect_uri=" + encodeURI(window.location.protocol + "//" + hostname + (R.isNil(window.location.port) || window.location.port === '' ? '' : (':' + window.location.port))) + "&response_mode=fragment&response_type=token+id_token&scope=openid+" + encodeURI("api:chd-poc") + "&nonce=" + nonce +(!R.isNil(state) ? ('&state='+state): '');
}


function getQueryVariable(variable) {
    const query = window.location.hash.substring(1);
    const vars = query.split('&');
    const keyValue = {}
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        keyValue[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return keyValue;
}

export const handleAuthentication = () => {
    const query = getQueryVariable();
    let token = undefined;
    if (query.access_token !== undefined) {
        sessionStorage.removeItem("access_token");
        sessionStorage.setItem("access_token", query.access_token);
        token = query.access_token
    } else {
        token = sessionStorage.getItem("access_token")
    }

    if(R.isNil(token)){
        window.location = getAuthenticationUrl(null);
        return;
    }

    try{
        const decoded = jwt_decode(token);
        if(Date.now() >= decoded.exp * 1000){
            window.location = getAuthenticationUrl(null);
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + sessionStorage.getItem("access_token");
    }
    catch(err){
        throw err;
    }
    
        // if (query.id_token !== undefined) {
        //     sessionStorage.removeItem("id_token");
        //     sessionStorage.setItem("id_token", query.id_token);
        // }

        // let state = new URLSearchParams(window.location.search).get("state");
        // if (state === null || state === undefined) {
        //     state = query.state;
        // }
        // if (state !== undefined && state !== null && !state.startsWith('/')) {
        //     let idToken = sessionStorage.getItem('id_token');
        //     self.props.activateAccount(state, idToken);
        // }
        // else {
        //     if (self.props.authentication.user === undefined) {
        //         let idToken = localStorage.getItem('id_token');
        //         self.props.createUser(idToken);
        //     }
        // }
}