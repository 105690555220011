import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'
import * as parseDocumentApi from '../APIs/parseApi';


export const parseDocument = createAsyncThunk(
    'document/parse',
    async (params) => {
      const json = await parseDocumentApi.parseFile(params.file, params.env, params.docType)
      console.log("params", params)
      return json
    }
);


const authenticationSlice = createSlice({
    name: "authentication",
    initialState: {
        isParsing: false,
        pdfFormData: null,
        parsedValue: null
    },
    reducers:{
        addPdfFormData: (state, action) => {
            state.pdfFormData = action.payload;
        },

        clearPdfFormData: (state) => {
            state.isParsing = false;
            state.pdfFormData = null;
            state.parsedValue = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(parseDocument.pending, (state, action) => {
            state.isParsing = true;
        })
        .addCase(parseDocument.fulfilled, (state, action) => {
            console.log(action.payload)
            state.parsedValue = action.payload;
            state.isParsing = false;
        })
        .addCase(parseDocument.rejected, (state, action) => {
            state.isParsing = false;
        })

    }
        
});

export const { addPdfFormData, clearPdfFormData } = authenticationSlice.actions;
export default authenticationSlice.reducer;

