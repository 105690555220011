import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import {addPdfFormData, clearPdfFormData, parseDocument} from '../reducers/DocumentSlice';

import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import logo from '../assets/logo.svg';

// import {addDocumentInPlayground, clearDocumentInPlayground, parseDocumentInPlayground} from '../../../actions/settings-api';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const App = () => {
  const fileSelectorRef = useRef();
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const [env, setEnv] = useState("staging");
  const [docType, setDocType] = useState("invoices");
  const documentSelector = useSelector(state => state.document);
  function makeReplacer(key, value) {
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return value.toFixed(2);
    return value;

  };

  return (
    <article className="playground">
      <header className="playground-header flex-row align-items-center justify-content-between">
        <div className="col">
          <h1 className="fw-medium fs-lg flex-row align-items-center gap-2 my-auto">
            <img src={logo} alt={logo} width="24px" height="24px" className='img-fluid' />
            <span>Playground</span>
          </h1>
        </div>
        <div className="col-auto ml-auto">
          <nav className="navbar">  
          <div className="nav-item">
            <label className="fw-small">Doc. Type:</label>
          </div>
          <div className="nav-item">
            <label className="btn">
              <input type="radio" name="docType" value="invoices" checked={docType==="invoices"} onChange={e => setDocType(e.target.value)} /> Invoice
            </label> 
            <label className="btn"> 
              <input type="radio" name="docType" value="payslips" checked={docType==="payslips"} onChange={e => setDocType(e.target.value)} /> Payslip
            </label>  
          </div>
          <div className="nav-item">
            <label>Env.:</label>
          </div>
            <div className="nav-item">
              <label className="btn">
                <input type="radio" name="environment" value="staging" checked={env==="staging"} onChange={e => setEnv(e.target.value)} /> Staging
              </label> 
              <label className="btn"> 
                <input type="radio" name="environment" value="production" checked={env==="production"} onChange={e => setEnv(e.target.value)} /> Production
              </label>  
            </div>
            <div className="nav-item"> 
              <button type="button" className="btn btn-success py-2" onClick={(e) => {e.preventDefault(); fileSelectorRef.current.value = null; dispatch(clearPdfFormData());}}>Reset</button>
            </div>
          </nav> 
        </div>
      </header>
      <form action="/" id="page" method="POST" className="playground-body">
        <input type="file" id="fileElem" accept="application/pdf" style={{display: "none"}} ref={fileSelectorRef} onChange={(event) => {
                        const fileUrl = URL.createObjectURL(event.target.files[0]);
                        dispatch(addPdfFormData(fileUrl));
                      }} />
          <div className="playground-container flex-column flex-lg-row">
            <div className="playground-src playground-item mw-lg-50">
              {
                R.isNil(documentSelector.pdfFormData) ? 
                  <>
                    <div className="playground-label">
                      Source: {
                        'Upload and parse a PDF to test the ParseXtract'
                      }
                    </div>
                    <div className="playground-code">
                      
                      <button type="button" className="playground-droparea"
                        onClick={
                          (event) => {
                            event.preventDefault();
                            fileSelectorRef.current.click();
                          }
                        }
                        >
                          <div className="icns mdi-progress-upload h1 lh-copy d-block"></div>
                          <div className="fs-lg mb-sm"><u className="text-primary">Browse</u> to choose a file</div>
                          <div className="small ml-sm va-middle d-block text-gray ">
                            <div className="mx-auto container-narrow min-requirements">
                            (pdf and images are accepted, <span className="d-inline-block">up to 10MB each)</span>
                            </div>
                          </div>
                      </button>	
                    </div>
                  </>
                :
                <div className="playground-code">
                  <Document file={documentSelector.pdfFormData}  onLoadSuccess={({ numPages })=>setNumPages(numPages)}>
                    {
                    Array.apply(null, Array(numPages))
                      .map((x, i)=>i+1)
                      .map(page => <Page pageNumber={page}/>)
                    }
                  </Document>
                </div>
              }
              
            </div>
            <div className="playground-output playground-item mw-lg-50">
              <div className="playground-label">
                Output: Your parsed pdf converted to json file
              </div>
              <div className="playground-code">
                <pre><code>
                  {
                      documentSelector.isParsing ?
                        "Parsing document..."
                      :
                        R.isNil(documentSelector.parsedValue) ?
                          "N/A"
                        : 
                          JSON.stringify(documentSelector.parsedValue, makeReplacer, 2)
                  }
                </code></pre>
              </div>
            </div>
          </div>
          <div className={"playground-btn z-1" + (documentSelector.isParsing || R.isNil(documentSelector.pdfFormData) ? ' disabled' : '')} onClick={() => {
            if(fileSelectorRef.current.files.length > 0){
              dispatch(parseDocument({file: fileSelectorRef.current.files[0], env, docType}));
            }
          }
          }>Parse</div>
          {/* só tens de adicionar a class disabled quando estive a parsar :) */}
      </form>
    </article>
  );
}