import axios from 'axios';

export const parseFile = async (file, env, docType) =>{
    var formData = new FormData();
    formData.append("File", file);
    let url = "https://chd-poc.securibox.eu";
    let documentType = "documents";
    if (env === "staging"){
        url = "https://chd-poc-staging.azurewebsites.net";
    } 
    if (docType=== "payslips"){
        documentType = "payslips"
    }

    const response = await axios.post(`${url}/api/v1/${documentType}/parse`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    console.log("response api", response);
    return response.data;
}