import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import documentSlice from './DocumentSlice';

export const store = configureStore({
    reducer: {
        document: documentSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(logger),
});
